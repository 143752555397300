import {
  BillSendPayload,
  IAddPartPayload,
  IGetPaymentLinkPayload,
  IPaymentDetails,
  IPaymentStatus,
  IPaymentStatusPayload,
  SaveCustomerDataPayload,
} from '@/types';
import axiosInstance from '@clubpay/customer-common-module/src/repository/axios';
import { getSession } from '@clubpay/customer-common-module/src/service/session/dsi';

export async function getPaymentLinkById({
  cc,
  restaurantUnique,
  orderId,
  hash,
  f1 = '_',
  f2 = '_',
  diningSessionID,
}: IGetPaymentLinkPayload): Promise<IPaymentDetails> {
  const res = await axiosInstance.Get<IPaymentDetails>(
    `/payment/details/${cc}/${restaurantUnique}/${orderId}/${f1}/${f2}/${hash}?id=${getSession()}&fetchMode=payment_link${
      diningSessionID ? '&diningSessionID=' + diningSessionID : ''
    }`,
  );
  return { ...res.data, jwt: res.data?.JWTToken };
}

export async function addParty(
  payload: IAddPartPayload,
): Promise<IPaymentDetails> {
  const res = await axiosInstance.Get<IPaymentDetails>(
    `/payment/add-party/${payload.country}/${payload.restaurantUnique}/${payload.diningSessionID}`,
    {
      params: {
        id: payload.id,
      },
    },
  );

  return res.data;
}

export async function getStatus(
  payload: IPaymentStatusPayload,
): Promise<IPaymentStatus> {
  const res = await axiosInstance.Get<IPaymentStatus>(`/payment/status`, {
    params: {
      diningSessionID: payload.diningSessionID,
      reference: payload.reference,
      id: payload.id,
    },
  });

  return res.data;
}

export async function sendBill(payload: BillSendPayload) {
  const res = await axiosInstance.Post<IPaymentStatus>(
    `/payment-link/send-email/${payload.dsi}/${payload.id}`,
    {
      email: payload.email,
      reference: payload.reference,
    },
    {
      params: {
        cc: payload.cc,
        lang: payload.lang,
        tz: payload.tz,
      },
    },
  );

  return res.data;
}

export async function doPostPaymentActions(payload: SaveCustomerDataPayload) {
  const res = await axiosInstance.Patch<IPaymentStatus>(
    `/payment-link/post-payment/${payload.dsi}`,
    {
      ...(payload.email ? { email: payload.email } : {}),
      ...(payload.phone ? { phone: payload.phone } : {}),
      ...(payload.firstName ? { firstName: payload.firstName } : {}),
      ...(payload.lastName ? { lastName: payload.lastName } : {}),
      partyId: getSession(),
      reference: payload.reference,
    },
  );

  return res.data;
}
